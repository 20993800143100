import "app/landing/landing.scss";

import {Offcanvas} from "bootstrap";
import CalendarSvg from "./assets/calendar.svg?react";
import MedalSvg from "./assets/medal.svg?react";
import ReportSvg from "./assets/report.svg?react";
import login2 from "app/landing/assets/login2.jpg";
import home from "app/landing/assets/home.jpg";
import tablet from "app/landing/assets/tablet.jpg";
import welcomePhone from "app/landing/assets/welcome_phone.jpg";
import dashboard from "./assets/dashboard.jpg";

import {Screenshot} from "app/landing/Screenshot";
import {PhoneAndTablet} from "app/landing/PhoneAndTablet";
import {RefCallback, useCallback, useRef} from "react";
import {useUnmount} from "@fluentui/react-hooks";
import {styled} from "styled-components";
import LogoSvg from "app/utils/icons/logo.svg?react";
import {Trans, useTranslation} from "react-i18next";
import {supportedLanguages} from "app/employee/i18n";

import FlagBgSvg from "./flags/bg.svg?react";
import FlagGbSvg from "./flags/gb.svg?react";
import FlagRuSvg from "./flags/ru.svg?react";
import FlagZhSvg from "./flags/zh.svg?react";
import LanguageSvg from "bootstrap-icons/icons/translate.svg?react";

import {BootstrapDropdown} from "app/landing/BootstrapDropdown";

const Logo = styled(LogoSvg)`
  height: 3.5rem;
`;

const svgFlags = {
  "bg-BG": <FlagBgSvg />,
  "en-GB": <FlagGbSvg />,
  "ru-RU": <FlagRuSvg />,
  "zh-CN": <FlagZhSvg />
} as const;

const IconWrapper = styled.span`
  min-width: 1.5rem;
  line-height: 1rem;
`;

const LanguageWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0 0.5rem;
`;

const landingSupportedLanguages = supportedLanguages
  .filter((lang) => lang.code === "en-GB" || lang.code === "bg-BG" || lang.code === "ru-RU" || lang.code === "zh-CN")
  .map((l) => ({
    ...l,
    svgFlag: svgFlags[l.code]
  }));

export const Landing = () => {
  const {t, i18n} = useTranslation("landing");
  const offCanvasRef = useRef<Offcanvas | null>(null);
  const offCanvas: RefCallback<HTMLDivElement> = useCallback((element) => {
    offCanvasRef.current?.dispose();
    offCanvasRef.current = null;
    if (element) {
      offCanvasRef.current = new Offcanvas(element, {scroll: true});
    }
  }, []);
  useUnmount(() => {
    offCanvasRef.current?.dispose();
    offCanvasRef.current = null;
  });

  const closeMenu = useCallback(() => {
    offCanvasRef.current?.hide();
  }, []);

  const toggleOffCanvas = useCallback(() => {
    offCanvasRef.current?.toggle();
  }, []);

  const thisLanguage =
    landingSupportedLanguages.find((lang) => lang.code === i18n.language) ?? landingSupportedLanguages[0];

  const privacyPolicy = t("privacy-policy-url", {defaultValue: "/legal/privacy-policy/latest"});

  return (
    <>
      <div className="container-fluid primary-section">
        <nav className="navbar navbar-expand-xl">
          <div className="container-fluid">
            <a className="navbar-brand d-inline-flex align-items-center gap-2" href="#">
              <Logo />
              <div className="d-flex align-items-center">
                <div className="d-inline-flex flex-column align-items-center gap-2">
                  <span className="fs-2 lh-1">
                    <Trans i18nKey="title" t={t}>
                      Food Safety Academy
                    </Trans>
                  </span>
                  <span className="fs-6 lh-1 d-none d-sm-inline">
                    <Trans i18nKey="subtitle" t={t}>
                      Обучение по безопасност на храните
                    </Trans>
                  </span>
                </div>
              </div>
            </a>
            <button className="navbar-toggler" onClick={toggleOffCanvas} type="button">
              <svg height={30} viewBox="0 0 30 30" width={30} xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4 7h22M4 15h22M4 23h22"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
              </svg>
            </button>
            <div className="offcanvas offcanvas-end primary-section" ref={offCanvas}>
              <div className="offcanvas-header">
                <div className="offcanvas-title d-inline-flex align-items-center gap-2">
                  <Logo />
                  <div className="d-flex align-items-center">
                    <div className="d-inline-flex flex-column align-items-center gap-2">
                      <span className="fs-2 lh-1">
                        <Trans i18nKey="title" t={t}>
                          Food Safety Academy
                        </Trans>
                      </span>
                      <span className="fs-6 lh-1 d-none d-lg-inline">
                        <Trans i18nKey="subtitle" t={t}>
                          Обучение по безопасност на храните
                        </Trans>
                      </span>
                    </div>
                  </div>
                </div>
                <button aria-label="Close" className="btn-close" data-bs-dismiss="offcanvas" type="button" />
              </div>
              <div className="offcanvas-body justify-content-end">
                <ul className="navbar-nav p-2">
                  <li className="nav-item">
                    <a className="nav-link px-3" href="#начало" onClick={closeMenu}>
                      <Trans i18nKey="nav.home" t={t}>
                        Начало
                      </Trans>
                    </a>
                  </li>
                  <li className="nav-item separator" />
                  <li className="nav-item">
                    <a className="nav-link px-3" href="#обучения" onClick={closeMenu}>
                      <Trans i18nKey="nav.course" t={t}>
                        Обучения
                      </Trans>
                    </a>
                  </li>
                  <li className="nav-item separator" />
                  <li className="nav-item">
                    <a className="nav-link px-3" href="#защо-онлайн" onClick={closeMenu}>
                      <Trans i18nKey="nav.why-online" t={t}>
                        Защо Онлайн?
                      </Trans>
                    </a>
                  </li>
                  <li className="nav-item separator" />
                  <li className="nav-item">
                    <a className="nav-link px-3" href="#за-нас" onClick={closeMenu}>
                      <Trans i18nKey="nav.about-us" t={t}>
                        За нас
                      </Trans>
                    </a>
                  </li>
                  <li className="nav-item separator" />
                  <li className="nav-item">
                    <a className="nav-link px-3" href="#контакти" onClick={closeMenu}>
                      <Trans i18nKey="nav.contacts" t={t}>
                        Контакти
                      </Trans>
                    </a>
                  </li>
                  {thisLanguage && (
                    <>
                      <li className="nav-item separator" />
                      <li className="nav-item">
                        {/*<a className="nav-link px-3" href={`?lang=${otherLanguage.code}`} onClick={closeMenu}>*/}
                        {/*  <span className="flag">{otherLanguage.flag}</span> {otherLanguage.name}*/}
                        {/*</a>*/}
                        <BootstrapDropdown
                          className="nav-link px-3"
                          value={
                            <LanguageWrapper>
                              <IconWrapper>
                                <LanguageSvg />
                              </IconWrapper>
                              <IconWrapper>{thisLanguage.svgFlag}</IconWrapper>
                            </LanguageWrapper>
                          }
                        >
                          {landingSupportedLanguages.map((l) => (
                            <a className="dropdown-item" href={`?lang=${l.code}`} key={l.code}>
                              <LanguageWrapper>
                                <IconWrapper>{l.svgFlag}</IconWrapper> {l.name}
                              </LanguageWrapper>
                            </a>
                          ))}
                        </BootstrapDropdown>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="container-fluid gradient-section-row" id="начало">
        <div className="container py-5">
          <div className="row align-items-center g-5 py-5">
            <div className="col-12 col-lg-6 d-flex flex-column text-center text-lg-start align-items-stretch align-items-lg-start">
              <h1 className="display-3 fw-bold lh-1 mb-3">
                <Trans i18nKey="sec1.title1" t={t}>
                  Открийте нови знания!
                </Trans>
              </h1>
              <h2 className="display-7 lh-1 mb-5">
                <Trans i18nKey="sec1.title2" t={t}>
                  Учете навсякъде
                </Trans>
              </h2>
              <h3 className="display-6 lh-1 mb-5">
                <Trans i18nKey="sec1.title3" t={t}>
                  Обучение по безопасност на храните
                </Trans>
              </h3>
              <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                <a
                  className="btn btn-primary btn-lg px-4 me-md-2 main-button-1"
                  href={`/employees?lang=${thisLanguage?.code}`}
                  type="button"
                >
                  <Trans i18nKey="sec1.button-employee-login" t={t}>
                    Вход за служители
                  </Trans>
                </a>
                <a className="btn btn-secondary btn-lg px-4 main-button-2" href="/managers" type="button">
                  <Trans i18nKey="sec1.button-manager-login" t={t}>
                    Вход за фирми
                  </Trans>
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <PhoneAndTablet phone={welcomePhone} tablet={tablet} />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid secondary-section-row" id="обучения">
        <div className="container px-4 py-5">
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-2">
            <div className="col secondary-section p-5">
              <h2 className="fs-1">
                <Trans i18nKey="sec2.text1" t={t}>
                  Добавете нови знания по безопасност на храните
                </Trans>
              </h2>
            </div>
            <div className="col primary-section p-5">
              <h2 className="fs-1 pb-5">
                <Trans i18nKey="sec2.text2" t={t}>
                  Учете навсякъде, по всяко време и когато <strong>Вие</strong> решите.
                </Trans>
              </h2>
              <p className="fs-5">
                <Trans i18nKey="sec2.text3" t={t}>
                  Нашата платформа Ви предоставя възможността да допълните пропуските в знанията по безопасност на
                  храните в един нов <strong>мобилен</strong> вариант.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid gradient-section-row" id="защо-онлайн">
        <div className="container px-4 py-5 ">
          <h2 className="pb-2 fs-1">
            <Trans i18nKey="sec3.title1" t={t}>
              Предимства
            </Trans>
          </h2>
          <div className="row fs-4">
            <p>
              <Trans i18nKey="sec3.text1" t={t}>
                Основната ни цел е да Ви предоставим качествена, синтезирана информация и знания, които да бъдат
                прилагани ежедневно от вашите служители в дейностите си, свързани с обработката на храната и нейната
                безопасност.
              </Trans>
            </p>
          </div>
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
            <div className="feature col">
              <h3 className="fs-1 mb-5 d-flex align-items-center gap-3">
                <CalendarSvg className="bi" height="1em" width="1em" />
                <Trans i18nKey="sec3.title2" t={t}>
                  Планиране
                </Trans>
              </h3>
              <div className="fs-4">
                <Trans i18nKey="sec3.text2" t={t}>
                  <ul>
                    <li>Лесно организиране на обученията спрямо нуждите и заеманите позиции на служителите.</li>
                    <li>Опростено планиране във времето, през календара на приложението.</li>
                  </ul>
                </Trans>
              </div>
            </div>
            <div className="feature col">
              <h3 className="fs-1 mb-5 d-flex align-items-center gap-3">
                <MedalSvg className="bi" height="1em" width="1em" />
                <Trans i18nKey="sec3.title3" t={t}>
                  Ефективност
                </Trans>
              </h3>
              <div className="fs-4">
                <Trans i18nKey="sec3.text3" t={t}>
                  <ul>
                    <li>Синтезирана информация, поднесена по лесен за усвояване начин </li>
                    <li>24/7 достъп до персонализирани нива на обучения.</li>
                    <li>Индивидуални кодове за достъп. </li>
                    <li>Тестова форма на проверка на знанията.</li>
                  </ul>
                </Trans>
              </div>
            </div>
            <div className="feature col">
              <h3 className="fs-1 mb-5 d-flex align-items-center gap-3">
                <ReportSvg className="bi" height="1em" width="1em" />
                <Trans i18nKey="sec3.title4" t={t}>
                  Отчетност и контрол
                </Trans>
              </h3>
              <div className="fs-4">
                <Trans i18nKey="sec3.text4" t={t}>
                  <ul>
                    <li>
                      Мениджмънт административен панел, позволяващ бърза проверка прогреса на текущите обучения и
                      планиране на нови такива.
                    </li>
                  </ul>
                </Trans>
              </div>
            </div>
          </div>
          <div className="row fs-4">
            <p>
              <Trans i18nKey="sec4.list-title" t={t}>
                Още ползи за вашият бизнес от този вид обучения са:
              </Trans>
            </p>
          </div>
          <div className="row">
            <div className="col">
              <div className="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button fs-4"
                      data-bs-target="#features-1"
                      data-bs-toggle="collapse"
                      type="button"
                    >
                      <Trans i18nKey="sec4.title1" t={t}>
                        Законово съответствие.
                      </Trans>
                    </button>
                  </h2>
                  <div className="accordion-collapse collapse show" id="features-1">
                    <div className="accordion-body fs-5">
                      <Trans i18nKey="sec4.text1" t={t}>
                        Нашите обучения са базирани и съобразени с всички Европейски и Български закони , регламенти и
                        директиви по безопасност на храните. Според един от които – &quot;Работодателят трябва да
                        осигури обучение по безопасност на храните на всеки един служител работещ в хранителен обект
                        независимо от длъжността му.&quot;
                      </Trans>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed fs-4"
                      data-bs-target="#features-2"
                      data-bs-toggle="collapse"
                      type="button"
                    >
                      <Trans i18nKey="sec4.title2" t={t}>
                        Намаляване на разходите за обучение.
                      </Trans>
                    </button>
                  </h2>
                  <div
                    aria-labelledby="panelsStayOpen-heading2"
                    className="accordion-collapse collapse"
                    id="features-2"
                  >
                    <div className="accordion-body fs-5">
                      <Trans i18nKey="sec4.text2" t={t}>
                        Чрез онлайн достъпа до обученията, всеки служител може да го премине в удобно за него време, без
                        то да окаже влияние или прекъсване работния процес. Доста често организацията на живо на подобен
                        тип обучения – води след себе си непълна посещаемост, промяна на работното време и много други
                        чисто логистични проблеми.
                      </Trans>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-heading3">
                    <button
                      className="accordion-button collapsed fs-4"
                      data-bs-target="#features-3"
                      data-bs-toggle="collapse"
                      type="button"
                    >
                      <Trans i18nKey="sec4.title3" t={t}>
                        Подобряване качеството на храните.
                      </Trans>
                    </button>
                  </h2>
                  <div className="accordion-collapse collapse" id="features-3">
                    <div className="accordion-body fs-5">
                      <Trans i18nKey="sec4.text3" t={t}>
                        Обученията на служителите помага на служителите да научат как да приготвят и съхраняват храната
                        по начин, който да осигури максимална безопасност, качество и по-малко отпадъци.
                      </Trans>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed fs-4"
                      data-bs-target="#features-4"
                      data-bs-toggle="collapse"
                      type="button"
                    >
                      <Trans i18nKey="sec4.title4" t={t}>
                        Увеличаване на продуктивността.
                      </Trans>
                    </button>
                  </h2>
                  <div className="accordion-collapse collapse" id="features-4">
                    <div className="accordion-body fs-5">
                      <Trans i18nKey="sec4.text4" t={t}>
                        Служителите, които разбират как да работят с храни по безопасен начин, са по компетентни и
                        по-продуктивни в своята работа.
                      </Trans>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-heading5">
                    <button
                      className="accordion-button collapsed fs-4"
                      data-bs-target="#features-5"
                      data-bs-toggle="collapse"
                      type="button"
                    >
                      <Trans i18nKey="sec4.title5" t={t}>
                        Създаване на качествени кадри.
                      </Trans>
                    </button>
                  </h2>
                  <div className="accordion-collapse collapse" id="features-5">
                    <div className="accordion-body fs-5">
                      <Trans i18nKey="sec4.text5" t={t}>
                        Ние вярваме, че именно знанията са пътят към създаване на квалифицирани и полезни за бизнеса
                        кадри.
                      </Trans>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed fs-4"
                      data-bs-target="#features-6"
                      data-bs-toggle="collapse"
                      type="button"
                    >
                      <Trans i18nKey="sec4.title6" t={t}>
                        Подобрени маркетингови възможности.
                      </Trans>
                    </button>
                  </h2>
                  <div className="accordion-collapse collapse" id="features-6">
                    <div className="accordion-body fs-5">
                      <Trans i18nKey="sec4.text6" t={t}>
                        Ако бизнесът предлага храни, които са приготвени и съхранени по безопасен начин, това може да
                        бъде много важен фактор за маркетинга му. По този начин да привлече повече клиенти, като ги
                        увери в качеството на предлаганите от него храни.
                      </Trans>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed fs-4"
                      data-bs-target="#features-7"
                      data-bs-toggle="collapse"
                      type="button"
                    >
                      <Trans i18nKey="sec4.title7" t={t}>
                        Подобряване имиджа на марката.
                      </Trans>
                    </button>
                  </h2>
                  <div className="accordion-collapse collapse" id="features-7">
                    <div className="accordion-body fs-5">
                      <Trans i18nKey="sec4.text7" t={t}>
                        Практикуването на знанията от тези обучения би подобрило имиджа на марката и да доведе до повече
                        продажби.
                      </Trans>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid secondary-section-row">
        <div className="container px-4 py-5">
          <div className="row g-4">
            <h2 className="fs-1">
              <Trans i18nKey="sec5.title1" t={t}>
                Как работи?
              </Trans>
            </h2>
          </div>
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-2">
            <div className="primary-section col p-5">
              <h3 className="fs-6">/01</h3>
              <h3 className="fs-2">
                <Trans i18nKey="sec5.subtitle1" t={t}>
                  Планиране на обученията по нива, спрямо нуждите на служителите
                </Trans>
              </h3>
              <p className="fs-6">
                <Trans i18nKey="sec5.text1" t={t}>
                  В зависимост от позицията, която заемат, ние предлагаме различни нива на обучение.
                </Trans>
              </p>
            </div>
            <div className="col p-5 d-flex justify-content-center">
              <Screenshot className="phone-vert" image={home} />
            </div>
          </div>
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-2 flex-lg-row-reverse">
            <div className="col primary-section p-5">
              <h3 className="fs-6">/02</h3>
              <h3 className="fs-2">
                <Trans i18nKey="sec5.subtitle2" t={t}>
                  Достъп до обучението
                </Trans>
              </h3>
              <p className="fs-6">
                <Trans i18nKey="sec5.text2" t={t}>
                  Всеки служител получава персонален код за достъп спрямо, с който лесно може да влезе в системата по
                  всяко време. Административен панел с достъп до всички обучаващи се и техният прогрес.
                </Trans>
              </p>
            </div>
            <div className="col p-5 d-flex justify-content-center">
              <Screenshot className="phone-vert" image={login2} />
            </div>
          </div>
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-2">
            <div className="primary-section col p-5">
              <h3 className="fs-6">/03</h3>
              <h3 className="fs-2">
                <Trans i18nKey="sec5.subtitle3" t={t}>
                  Обучението
                </Trans>
              </h3>
              <p className="fs-6">
                <Trans i18nKey="sec5.text3" t={t}>
                  В зависимост от позицията, която заема,всеки служител ще премине през различен по брой и съдържание
                  теми. Като след всяка тема ще има възможност да провери знанията си чрез тест. Обучението се счита за
                  преминато само след покриване на определено ниво на успеваемост при тестовете.
                </Trans>
              </p>
            </div>
            <div className="col p-5 d-flex justify-content-center">
              <Screenshot className="phone-horiz" horizontal image={welcomePhone} />
            </div>
          </div>
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-2 flex-lg-row-reverse">
            <div className="col primary-section p-5">
              <h3 className="fs-6">/04</h3>
              <h3 className="fs-2">
                <Trans i18nKey="sec5.subtitle4" t={t}>
                  Отчетност и Контрол
                </Trans>
              </h3>
              <p className="fs-6">
                <Trans i18nKey="sec5.text4" t={t}>
                  Системата предоставя мониторинг върху прогреса на всички обучаващи се. Вградени са множество напомнящи
                  инструменти и лесно планиране на предстоящи обучения.
                </Trans>
              </p>
            </div>
            <div className="col p-5 d-flex justify-content-center">
              <img alt="Пример За Отчетност И Контрол" className="rounded img-fluid drop-shadow" src={dashboard} />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid gradient-section-row" id="за-нас">
        <div className="container py-5">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col pb-5">
              <h2 className="fs-1">
                <Trans i18nKey="sec6.title" t={t}>
                  За нас
                </Trans>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="fs-5">
                <Trans i18nKey="sec6.p1" t={t}>
                  Създадохме тези продукти и форма на обучение, вярвайки че качествената и безопасна храна е резултат от
                  всички тези знания – прилагани ежедневно.
                </Trans>
              </p>
              <p className="fs-5">
                <Trans i18nKey="sec6.p2" t={t}>
                  Нашият екип се надява, че с тези обучения ще допринеса за попълването на някой пропуски в знанията на
                  вашите служители и ще надгради вече изградените им добри навици.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid secondary-section-row" id="контакти">
        <div className="container px-4 pt-5">
          <div className="row row-cols-1 row-cols-lg-2 secondary-section">
            <div className="col p-5 ">
              <h2 className="fs-1">
                <Trans i18nKey="sec7.title" t={t}>
                  За всякакви въпроси относно този продукт
                </Trans>
              </h2>
            </div>
            <div className="col p-5 primary-section">
              <p className="fs-5">
                <Trans i18nKey="sec7.phone" t={t}>
                  Телефон: <a href="tel:+359895775512">+359 895 77 55 12</a>
                </Trans>
              </p>
              <p className="fs-5">
                <Trans i18nKey="sec7.email" t={t}>
                  Email: <a href="mailto:office@fsacademy.bg">office@fsacademy.bg</a>
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid secondary-section-row" id="кариери">
        <div className="container px-4 py-5">
          <div className="row row-cols-1 row-cols-lg-2 secondary-section">
            <div className="col p-5 ">
              <h2 className="pb-2 fs-3">
                <Trans i18nKey="sec8.title" t={t}>
                  Kариери
                </Trans>
              </h2>
              <h3 className="pb-2 fs-4">
                <Trans i18nKey="sec8.subtitle" t={t}>
                  Станeте част от екипа
                </Trans>
              </h3>
            </div>
            <div className="col p-5 primary-section fs-5">
              <Trans i18nKey="sec8.text" t={t}>
                Ако искате да участвате в разпространението на това обучение, свържете се с нас за повече информация на{" "}
                <a href="mailto:office@fsacademy.bg">office@fsacademy.bg</a> със заглавие{" "}
                <strong>Дистрибуция и разространение</strong>
              </Trans>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-2 py-4 fs-6 text-center">
        <Trans i18nKey="footer.copyright" t={t}>
          © 2025 Еф Ес Академи ООД ⋅{" "}
          <a href={privacyPolicy} rel="noreferrer" target="_blank">
            Политика за лични данни
          </a>
        </Trans>
      </div>
    </>
  );
};

export default Landing;
